.table_form{
    margin-top: 10px;
}
.table_row{
    display: grid;
    grid-template-columns: 20px 1fr 1.5fr;
    gap: 5px;
    border-bottom: solid #3c454f 1px;
    padding: 5px;
}

.row_header{
    border-top: #3c454f solid 1px;
    font-weight: 700;
}
.table_content{
    margin: 10px 0;
}
.title{
    font-weight: 700;
    text-align: center;
}
.subtitle{
    text-transform: uppercase;
    padding: 10px 0;
}
.table_value a{
    color: #09455c;
}