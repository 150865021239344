footer{
    background-color: #3c454f;
}
.footer_content{
    display: grid;
    grid-template-columns: repeat(auto-fit, 320px);
    justify-content: center;
    padding: 0 20px;
    gap: 20px;
    color: #fff;
    margin: 20px 0 40px;
    font-size: 0.7rem;
}

.rexisites_title{
    color: #bbb;
    font-size: 1rem;
    padding-bottom: 10px;
}

.reg_num{
    padding-bottom: 5px;
}
.reg_num span{
    font-weight: 700;
    padding-right: 5px;
}

.footer_block{
    display: flex;
    flex-direction: column;
    line-height: 1.2em;
    gap: 5px;
    padding: 5px 0;
}

.footer_block_title{
    color: #bbb;
}
@media (max-width: 750px) {
    .footer_content{
        grid-template-columns: repeat(auto-fit, 220px);
    }

}
@media (max-width: 525px) {
    .footer_content{
        grid-template-columns: repeat(auto-fit, 320px);
        gap: 0px;
        color: #fff;
        margin: 10px 0 40px;
        font-size: 0.7rem;
    }
    .footer_block{
        line-height: 1.2em;
        gap: 5px;
        padding: 5px 0;
    }
    .footer_item{
        padding-bottom: 5px;
        border-bottom: #bbb solid 1px;
    }

}