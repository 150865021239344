/*Обнуление*/
*{
	padding: 0;
	margin: 0;
	border: 0;
}
*,*:before,*:after{
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
:focus,:active{outline: none;}
a:focus,a:active{outline: none;}
p {
	margin-bottom: 0;
}
nav,footer,header,aside{display: block;}

html,body{
	height: 100%;
	width: 100%;
	font-size: 100%;
	line-height: 1;
	font-size: 18px;
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}
input,button,textarea{font-family:inherit;}

input::-ms-clear{display: none;}
button{cursor: pointer;}
button::-moz-focus-inner {padding:0;border:0;}
a, a:visited{text-decoration: none;}
a:hover{text-decoration: none;}
ul li{list-style: none;}
img{vertical-align: top;}

h1,h2,h3,h4,h5,h6{font-size:inherit;font-weight: inherit;}
/*--------------------*/

body{
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: black;
    background-color: #e5e5e5;
}
.wrapper{
    display: grid;
    grid-template-rows: auto 1fr auto;
}
.container{
  max-width: 1080px;
  height: auto;
  margin: 0 auto;
  padding: 0;
}
h3{
  padding: 20px 0;
  font-size: 1.3rem;
}
