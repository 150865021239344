.file_name {
    width: 100%;
    height: 40px;
    color: #09455c;
    border: solid #dddddd 2px;
    border-radius: 3px;
    padding-left: 10px;
    margin-bottom: 10px;
}
.file_name:focus{
    border: solid #09455c 2px;
}
.hidden{
    opacity: 0;
    width: 0;
    height: 0;
    line-height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
}