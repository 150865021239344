.all_content {
    position: relative;
    display: flex;
    height: 100%;
    min-height: 80vh;
    width: 100%;
}

.content_main {
    background-color: #fff;
    width: 100%;
}

.page_titile {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.page_titile img {
    padding-block: 10px;
    padding-inline: 10px;
    max-width: 300px;
    width: 100%;
    height: auto;
}

.page_titile h1 {
    text-transform: uppercase;
}

.page_subtitle {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 30px;
}
.subtitle{
    text-align: center;
}

.page_subtitle h1 {
    text-align: center;
    font-weight: 700;
}
.page_subtitle h2 {
    text-align: center;
}

.page_subtitle ul li{
    list-style-type: "-";
    padding-block-end: 5px;
    padding-inline-start: 5px;
    line-height: 1.4;
}

.files_title_text {
    font-weight: 700;
}

.page_files {
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 10px;
    background-color: #dbd9d9;
    min-height: 50px;
    max-height: max-content;
    padding: 5px;
    border: solid #dbd9d9 1px;
}

.page_files a {
    color: #09455c;
}

.page_files:nth-child(even) {
    background-color: #fff;
}

.about_wrapper {
    margin: 10px;
}

.btn_group {
    display: flex;
    flex-wrap: wrap;
    width: inherit;
    margin: 20px;
    justify-content: space-evenly;
    gap: 10px;
}

.btn_report {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    background-color: inherit;
    width: 250px;
    height: 40px;
    color: #09455c;
    border: solid #09455c 2px;
    border-radius: 3px;
}

.btn_report:hover {
    background-color: #a0c4d5;
    color: #fff;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;
}

.btn_report a {
    color: inherit;
}

.dropdown_pull {
    position: absolute;
    padding: 0;
    top: 38px;
    border: #09455c solid 1px;
    width: inherit;
    display: flex;
    flex-direction: column;
    background-color: #09455c;
    color: #fff;
    opacity: 0;
    visibility: hidden;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.btn_report:hover .dropdown_pull {
    cursor: pointer;
    border-top: none;
    opacity: 1;
    transition: opacity 0.1s linear;
    visibility: visible;
}

.dropdown_pull li {
    padding: 10px;
}

.dropdown_pull li:hover {
    background-color: #a0c4d5;
}

@media(max-width: 750px) {
    .content_main {
        padding-left: 50px;
    }
    .page_subtitle {
        margin: 10px;
    }
}