.btn-modal{
    display: flex;
    margin: 10px 0;
    justify-content: flex-end;
}

.btn_download{
    background-color: inherit;
    color: #09455c;
    font-size: 0.8rem;
}
.delete_file{
    fill: #09455c;
    position: absolute;
    right: 1%;
    top: 35%;
    cursor: pointer;
}
.documents{
    margin: 0 30px 50px;
}
