.authBlock{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5vh;
}
.authBlock h2{
    font-size: 1.2rem;
    font-weight: 700;
    color: #09455c;
}

.authForm{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.authForm input {
    width: 250px;
    height: 40px;
    color: #09455c;
    border: solid #dddddd 2px;
    border-radius: 3px;
    padding-left: 10px;
}
.authForm input:focus{
    border: solid #09455c 2px;
}
.btn_enter {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #a0c4d5;
    width: 250px;
    height: 40px;
    color: #fff;
    border-radius: 3px;
}
.btn_enter:hover{
    background-color: #09455c;

}
/* <a href="https://ru.freepik.com/free-vector/city-skyline-concept-illustration_28205246.htm#query=%D0%B3%D0%BE%D1%80%D0%BE%D0%B4%D1%81%D0%BA%D0%B8%D0%B5%20%D0%B4%D0%BE%D0%BC%D0%B0&position=11&from_view=search&track=ais">Изображение от storyset</a> на Freepik */